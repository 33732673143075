<template>
  <div class="u-actual-news-list">
    <div v-if="breakpoint.isLow" class="u-actual-news-list__slider">
      <Swiper :options="swiperOptions" class="u-actual-news-list__swiper">
        <SwiperSlide
          class="u-actual-news-list__swiper-slide"
          v-for="(card, i) in newsList"
          :key="`u-actual-news-list__swiper-slide-${i}`"
        >
          <UNewsCard
            class="u-actual-news-list__swiper-card"
            :cardData="card"
            :learnMoreBtn="learnMoreBtnLang"
            :withoutAnonce="withoutAnonce"
            size="large"
          />
        </SwiperSlide>
      </Swiper>

      <div
        v-show="newsList?.length > 1"
        class="u-actual-news-list__pagination slider-pagination slider-pagination--theme-primary"
        :class="`u-actual-news-list__pagination-${_uid}`"
      />
    </div>

    <div v-else class="u-actual-news-list__list">
      <UNewsCard
        v-for="(card, i) in newsList"
        :key="`u-actual-news-list__card-${i}`"
        class="u-actual-news-list__card"
        :withoutAnonce="withoutAnonce"
        :size="newsCardSizeClass(i)"
        :learnMoreBtn="learnMoreBtnLang"
        :cardData="card"
      />
    </div>
  </div>
</template>

<script>
export default {
  inject: ['breakpoint'],

  props: {
    lang: {
      type: String,
      default: 'ru'
    },
    newsList: {
      type: Array,
    },
    indexLarge: {
      type: Number,
      default: 0,
    },
    withoutAnonce: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    learnMoreBtnLang() {
      return this.lang === 'cn' ? '了解更多' : 'Узнать больше'
    },
    swiperOptions() {
      return {
        slidesPerView: this.newsList?.length > 1 ? 1.05 : 1,
        spaceBetween: 16,
        pagination: {
          el: `.u-actual-news-list__pagination-${this._uid}`,
          type: 'bullets',
          bulletClass: 'slider-pagination__dot',
          bulletActiveClass: 'slider-pagination__dot--is-active',
          clickable: true
        },
        breakpoints: {
          768: {
            slidesPerView: this.newsList?.length > 1 ? 2 : 1,
          }
        }
      }
    }
  },
  methods: {
    newsCardSizeClass(index) {
      return (index - 1) % 5 === this.indexLarge ? 'large' : 'default'
    },
  }
}
</script>

<style lang="scss">
@import '@/scss/base/includes';

.u-actual-news-list {

  // .u-actual-news-list__list
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: -8px;
  }

  // .u-actual-news-list__card
  &__card {
    padding: 8px;
  }

  // .u-actual-news-list__swiper
  &__swiper {
    @include tablet {
      overflow: unset;
    }

    .u-news-card {
      height: 100%;
      width: 100%;
      max-width: 100%;
    }

    // .u-actual-news-list__swiper-slide
    &-slide {
      height: auto;
    }
  }

  // .u-actual-news-list__pagination
  &__pagination {
    margin-top: 24px;
  }
}
</style>
