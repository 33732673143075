import './scss/app.scss';
import 'swiper/css/swiper.css';

import Vue from 'vue';
import registerVueDirectives from './directives';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import VueRellax from 'vue-rellax';
import Vuebar from 'vuebar';
import Vue2TouchEvents from 'vue2-touch-events';
// eslint-disable-next-line
import yaMetrics from './utils/yaMetrics';
import decodeCyrillicDomain from '@/helpers/decodeCyrillicDomain.js';

import store from './store'

registerVueDirectives();

Vue.use(VueAwesomeSwiper);
Vue.use(VueRellax);
Vue.use(Vuebar);
Vue.use(Vue2TouchEvents);

const files = require.context('./components', true, /\.vue$/i);
files
  .keys()
  .map((key) =>
    Vue.component(key.split('/').pop().split('.')[0], files(key).default)
  );


new Vue({
  store,
  data() {
    return {
      menuOpened: false,
      breakpoint: this.getBreakpoint(),
      resizeObserver: null,
    };
  },
  provide() {
    return {
      breakpoint: this.breakpoint,
      ymHandler: this.ymHandler,
    };
  },
  created() {
    history.scrollRestoration = 'manual';
    this.checkUserAgent();

    window.addEventListener('resize', this.updateBreakpoint, { passive: true });
    window.addEventListener('resize', this.setRootSizes);
    this.$nextTick(() => {
      this.setRootSizes()
    })
  },
  mounted() {
    document.addEventListener('copy', this.addCitationAndLinkOnCopy);
    this.resizeObserver = new ResizeObserver(this.resizeObserverHandler)
    this.resizeObserver.observe(document.body)
  },
  beforeDestroy() {
    document.removeEventListener('copy', this.addCitationAndLinkOnCopy);
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.updateBreakpoint);
      window.removeEventListener('resize', this.setRootSizes);
    }

    this.resizeObserver?.unobserve(document.body)
  },
  methods: {
    getBreakpoint() {
      const { innerWidth } = window;
      return {
        isDesktop: innerWidth < 9999,
        isLow: innerWidth < 1280,
        isTablet: innerWidth < 1024,
        isMobile: innerWidth < 768,
      };
    },
    updateBreakpoint() {
      const { isDesktop, isLow, isTablet, isMobile } = this.getBreakpoint();
      this.breakpoint.isDesktop = isDesktop;
      this.breakpoint.isLow = isLow;
      this.breakpoint.isTablet = isTablet;
      this.breakpoint.isMobile = isMobile;
    },
    toggleMenu() {
      this.menuOpened = !this.menuOpened;

      if (this.menuOpened) {
        this.fixScrollBarWidth()
        this.$nextTick(() => {
          this.setMenuLinkEvent();
        });
      } else {
        this.fixScrollBarWidth()
        this.setRootSizes()
      }
    },
    fixScrollBarWidth() {
      const header = document.querySelector('.header');
      const delta = window.innerWidth - document.documentElement.clientWidth;

      header.style.paddingRight = `${delta}px`
    },
    closeMenu() {
      this.menuOpened = false;

      this.setRootSizes()
    },
    setMenuLinkEvent() {
      const nav = document.querySelector('.header__nav');

      if (nav) {
        const links = nav.querySelectorAll('a');

        links.forEach((link) => {
          link.addEventListener('click', this.closeMenu);
        });
      }
    },
    checkUserAgent() {
      const body = document.body;
      const agent = navigator.userAgent;

      if (/^((?!chrome|android).)*safari/i.test(agent)) {
        body.classList.add('is-safari');
      }
    },
    ymHandler(data) {
      const code = data.code || 90561506
      const type = data.type || 'reachGoal'
      const operation = data.operation || ''
      const options = data.options || {}

      const ymData = [code, type, operation]

      if (data.participateUrl) {
        options[data.participateUrl] = window.location.origin
      }

      if (options && Object.values(options).length) {
        ymData.push(options)
      }

      window.ym(...ymData)
    },
    setRootSizes() {
      this.$nextTick(() => {
        const header = document.querySelector('.header-main-block')
        const headerRect = header ? header.getBoundingClientRect().height : 0

        document.documentElement.style.setProperty('--header-height', `${Math.ceil(headerRect)}px`);
        document.documentElement.style.setProperty('--app-height', `${Math.ceil(window.innerHeight)}px`);
      })
    },
    addCitationAndLinkOnCopy(e) {
      const selection = window.getSelection()
      const copiedText = selection.toString()
      const formattedUrl = decodeCyrillicDomain(window.location.href)
      const citation = `Подробнее:\n${formattedUrl}`
      const newText = copiedText + '\n\n' + citation

      e.clipboardData.setData('text/plain', newText)
      e.preventDefault()
    },
    resizeObserverHandler(entries) {
      entries.forEach(() => {
        this.setRootSizes()
      });
    }
  },
}).$mount('#app');
