<template>
  <div class="business-program-person" :class="{ 'business-program-person--is-show-detail': isShowDetail }">
    <div class="business-program-person__inner">
      <img v-if="person.img" :src="person.img" :alt="person.name" class="business-program-person__img">
      <div class="business-program-person__content">
        <div class="business-program-person__header">
          <div class="business-program-person__title title-md">
            {{ person.name }}
          </div>
          <div v-if="person.online" class="business-program-person__status text-md tt-uppercase">
            ONLINE
          </div>
        </div>
        <div class="business-program-person__text text-md fw-400" v-html="person.positions" />
        <div v-if="person.text" class="business-program-person__detail text-md fw-400" @click="toggleDetailText">
          {{ personCaptionLang[lang] }}
          <InlineSvg class="business-program-person__detail-icon" src="down-chevron" />
        </div>
        <div v-if="person.text && isShowDetail" class="business-program-person__detail-text" v-html="person.text" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    person: {
      type: Object,
      default: () => ({})
    },
    lang: {
      type: String,
      default: 'ru'
    }
  },

  data() {
    return {
      personCaptionLang: {
        ru: 'Биография',
        en: 'Biography'
      },
      isShowDetail: false
    }
  },

  methods: {
    toggleDetailText() {
      this.isShowDetail = !this.isShowDetail
    }
  },
}
</script>

<style lang="scss">
@import '@/scss/base/includes.scss';

$b: '.business-program-person';
$img-size: 167px;

#{$b} {
  width: 100%;

  // .business-program-person__inner
  &__inner {
    display: flex;

    @include mobile {
      display: block;
    }
  }

  // .business-program-person__img
  &__img {
    min-width: $img-size;
    width: $img-size;
    max-width: $img-size;
    margin-right: 26px;
    height: auto;
    border-radius: 24px;

    @include mobile {
      width: 100%;
      max-width: 100%;
      margin: 0 auto 20px auto;
    }
  }


  // .business-program-person__header
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  // .business-program-person__status
  &__status {
    position: relative;
    color: $bg-brand;
    margin-left: 20px;
    padding-left: 15px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background-color: $bg-brand;
    }
  }

  // .business-program-person__text
  &__text {
    opacity: 0.4;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  // .business-program-person__detail
  &__detail {
    display: inline-flex;
    align-items: center;
    color: $bg-brand;
    margin-top: 16px;
    transition: all 0.3s ease;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    // .business-program-person__detail-icon
    &-icon {
      margin-left: 8px;

      #{$b}--is-show-detail & {
        svg {
          transform: rotate(180deg);
        }
      }

      svg {
        transition: transform 0.3s ease;
      }
    }
  }
}
</style>
